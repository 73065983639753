import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Button,
  ButtonGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";

import { SimpleAuths } from "./robleComponentes/SimpleAuths";
import { Routes } from "../routes";
// { useHistory } from "react-router-dom"
import { useHistory, useParams } from "react-router-dom";

var history;
function fetchAuth() {
  //let history = useHistory()
  console.log("Buscare " + authToFind);
  history.push({
    pathname: Routes.RobleAuth.path,
    state: { auth: authToFind },
  });
}

function setAuthToFind(e) {
  //console.log("data", e);
  authToFind = e.target.value;
  //console.log("dataA", authToFind);
}

let authToFind = "";

export default () => {
  history = useHistory();
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">          
          <h4>Autorizaciones</h4>
        </div>

        <Col className="" xs={1} md={1} lg={1} xl={1}></Col>

        <div className="btn-toolbar mb-2 mb-md-0">
          {/*<ButtonGroup>
              <Button variant="outline-primary" size="sm">Share</Button>
              <Button variant="outline-primary" size="sm">Export</Button>
            </ButtonGroup> */}
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/*               <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup> */}
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Mostrar
                </Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <SimpleAuths />
    </>
  );
};
