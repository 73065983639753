import React from "react";
import { Nav, Card, Pagination } from "@themesberg/react-bootstrap";

export const RoblePagination = (props) => {
  let paginaActual = props.paginaActual;
  let totalPaginas = props.totalPaginas;
  let paginasMostrables = props.paginasMostrables;
  let pageFetcher = props.pageFetcher;

  /* console.log("RoblePagination - paginaActual " + JSON.stringify(paginaActual));
  console.log("RoblePagination - totalPaginas " + JSON.stringify(totalPaginas));
  console.log(
    "RoblePagination - paginasMostrables " + JSON.stringify(paginasMostrables)
  ) */;

  return (
    <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
      <Nav>
        <Pagination className="mb-2 mb-lg-0">
          <Pagination.Prev onClick={() => pageFetcher(paginaActual - 1)}>
            Previous
          </Pagination.Prev>

          {paginasMostrables.map(function (pagina) {
            if (pagina === "..") {
              return <Pagination.Item>{pagina}</Pagination.Item>;
            }
            if (pagina === paginaActual) {
              return <Pagination.Item active>{pagina}</Pagination.Item>;
            } else {
              return (
                <Pagination.Item onClick={() => pageFetcher(pagina)}>
                  {pagina}
                </Pagination.Item>
              );
            }
          })}

          <Pagination.Next onClick={() => pageFetcher(paginaActual + 1)}>
            Next
          </Pagination.Next>
        </Pagination>
      </Nav>
      <small className="fw-bold">
        Mostrando <b>{paginaActual}</b> de <b>{totalPaginas}</b> páginas
      </small>
    </Card.Footer>
  );
};
