import React from "react";
import { Col, Row } from '@themesberg/react-bootstrap';

import { useLocation } from "react-router-dom";
import { PubTransaction } from "./robleComponentes/PubTransaction";

export default () => {

  const location = useLocation();
  let mTransaction = location.search.substring(1);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          {/*
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
              <Breadcrumb.Item active>Transacciones</Breadcrumb.Item>
            </Breadcrumb>
          */}          
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          {/*
            <ButtonGroup>
              <Button variant="outline-primary" size="sm">Share</Button>
              <Button variant="outline-primary" size="sm">Export</Button>
            </ButtonGroup>
          */}
          
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* 
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
            */}
            
          </Col>
          
        </Row>
      </div>
      
      <PubTransaction tx={mTransaction} />
    </>
  );
};
