
import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card } from '@themesberg/react-bootstrap';

export default (props) => {
  const currentYear = moment().get("year");


  return (
    <div>
      {/* {showSettings ? (
        <Card className="theme-settings">
          <Card.Body className="pt-4">

          </Card.Body>
        </Card>
      ) : (
        <Card className="theme-settings theme-settings-expand" onClick={() => { toggleSettings(true) }}>
          {
            <Card.Body className="p-3 py-2">
              <span className="fw-bold h6"><FontAwesomeIcon icon={faCogs} className="me-1 fs-7" /> Settings</span>
            </Card.Body> 
          }

        </Card>
      )} */}
      <footer className="footer section py-5">
        <Row>
          <Col xs={12} lg={12} className="mb-4 mb-lg-0">
            <p className="mb-0 text-center">
              Copyright © 2019-{`${currentYear} `}
              <Card.Link href="https://www.roblelabs.cl/" target="_blank" className="text-blue text-decoration-none fw-bold">
                RobleLabs
              </Card.Link>
            </p>
          </Col>
          {/*
            <Col xs={12} lg={6}>
              <ul className="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0">
                <li className="list-inline-item px-0 px-sm-2">
                  <Card.Link href="https://themesberg.com/about" target="_blank">
                    About
                  </Card.Link>
                </li>
                <li className="list-inline-item px-0 px-sm-2">
                  <Card.Link href="https://themesberg.com/themes" target="_blank">
                    Themes
                  </Card.Link>
                </li>
                <li className="list-inline-item px-0 px-sm-2">
                  <Card.Link href="https://themesberg.com/blog" target="_blank">
                    Blog
                  </Card.Link>
                </li>
                <li className="list-inline-item px-0 px-sm-2">
                  <Card.Link href="https://themesberg.com/contact" target="_blank">
                    Contact
                  </Card.Link>
                </li>
              </ul>
            </Col> */}


        </Row>
      </footer>

    </div>
  );
};
