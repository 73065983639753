/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import WidgetsStatsAll from "./components/widgetDash/widgetsStatsAll";
import MapaOSM from "./components/widgetDash/widgetMap";
import { CircleChart } from "../components/Charts";
import { SalesValueWidget } from "../components/Widgets";


export default () => {
  // Obtención de datos

  const [datosDash, setDatosDash] = useState({
    dash: null,
    latitud: null,
    longitud: null,
  });

  //const [datosGraf, setDatosGraf] = useState();

  let token = localStorage.getItem("token");

  useEffect(() => {
    obtenerDatos();
    /*obtenerDatosGraf();
    console.log("datosgraf: ",datosGraf);*/
  }, []);

  async function obtenerDatos(pagina, query) {
    let serviceUrl1 = process.env.REACT_APP_HOST_URL + "/dashboardGraphData";
    let serviceUrl2 = process.env.REACT_APP_HOST_URL + "/dashboardData";

    let tempDatosDash = {}; // objeto temporal para almacenar los datos

    //////////////////////////////////////////////////
    // Fetch para serviceUrl2

    await fetch(serviceUrl1, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        pagina: pagina,
        query: query,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          // Asignar valores adicionales al objeto temporal
          tempDatosDash.graphData = serviceResponse.data;
        }
      })
      .catch((err) => {
        // alert("Error");
      });

    await fetch(serviceUrl2, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        pagina: pagina,
        query: query,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          // Asignar valores adicionales al objeto temporal
          tempDatosDash.dash = serviceResponse.data;
        }
      })
      .catch((err) => {
        // alert("Error");
      });

    //////////////////////////////

    // Actualizar el estado datosDash una sola vez después de que se completen ambas llamadas fetch
    Promise.all([fetch(serviceUrl2), fetch(serviceUrl1)])
      .then((responses) => {
        setDatosDash(tempDatosDash);
      })
      .catch((err) => {
        // alert("Error");
      });
  }




  /*async function obtenerDatosGraf(pagina, query) {
    let serviceUrl1 = process.env.REACT_APP_HOST_URL + "/lastAuthsValidadas";
    let serviceUrl2 = process.env.REACT_APP_HOST_URL + "/lastFaceValidadas";
    let serviceUrl3 = process.env.REACT_APP_HOST_URL + "/lastDocsFirmados";

    let tempDatosDash = {}; // objeto temporal para almacenar los datos

    await fetch(serviceUrl1, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        pagina: pagina,
        query: query,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          // Asignar valores adicionales al objeto temporal
          tempDatosDash.AuthsValidadas = serviceResponse.data;
        }
      })
      .catch((err) => {
        // alert("Error");
      });

    await fetch(serviceUrl2, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        pagina: pagina,
        query: query,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          // Asignar valores adicionales al objeto temporal
          tempDatosDash.FaceValidadas = serviceResponse.data;
        }
      })
      .catch((err) => {
        // alert("Error");
      });

      await fetch(serviceUrl3, {
        method: "POST",
        headers: {
          "x-roble-key": "apikey",
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          token: token,
          pagina: pagina,
          query: query,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          let serviceResponse = response.data;
          if (serviceResponse.success) {
            // Asignar valores adicionales al objeto temporal
            tempDatosDash.DocsFirmados = serviceResponse.data;
          }
        })
        .catch((err) => {
          // alert("Error");
        });

    //////////////////////////////

    // Actualizar el estado datosDash una sola vez después de que se completen ambas llamadas fetch
    Promise.all([fetch(serviceUrl3), fetch(serviceUrl2), fetch(serviceUrl1)])
      .then((responses) => {
        setDatosGraf(tempDatosDash);
      })
      .catch((err) => {
        // alert("Error");
      });
  }*/









  console.log ("datosDash", datosDash);
  //console.log("datosgraf: ",datosGraf);


  // Fin datos obtenidos

  if (!datosDash.dash) {
    return <div>Cargando...</div>;
  }
  
  let ultimaFecha = new Date(datosDash.dash.ultimaValidacion.fecha);

  let ultimaLatitud= 0;
  let ultimaLongitud= 0;

  if( datosDash.dash && datosDash.dash.ultimaValidacion && datosDash.dash.ultimaValidacion.latitud ){
    ultimaLatitud= isNaN(datosDash.dash.ultimaValidacion.latitud) ? 0: datosDash.dash.ultimaValidacion.latitud;
    ultimaLongitud= isNaN(datosDash.dash.ultimaValidacion.longitud) ? 0 : datosDash.dash.ultimaValidacion.longitud;
  }

  return (       
    <>
      <div className="container-fluid card">
        <Row className="my-3">
          <Col className="col-8">
            <WidgetsStatsAll datosDash={datosDash.dash} />
          </Col>
          <Col className="col-4 text-center">
            <h4 className="my-3 ">Tasa Validaciones Exitosas</h4>
            <div className="wstats">
              <CircleChart                            
              datosDash={datosDash.dash} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="container-fluid card my-3">
        <Row className="my-3">
          <Col className="col-6 mb-3">
            
            <SalesValueWidget
              title="Documentos"
              value="10,567"
              valoresY={[ datosDash.graphData.documentosPorMes ]} 
              valoresX={datosDash.graphData.meses}
              percentage={10.57}
            />
            <br/>
            <SalesValueWidget
              title="Validaciones Faciales"
              value="10,567"
              valoresY={[ datosDash.graphData.facialesPorMes ]} 
              valoresX={datosDash.graphData.meses}
              percentage={10.57}
            />

          </Col>
          {/*<Col className="col-6 mb-3">
            <Col className="text-center">
              <h3 className="my-3">Lugar última autenticación</h3>
              <p>
                Latitud: { ultimaLatitud } || Longitud: { ultimaLongitud }
              </p>
              <p>
                { ultimaFecha.toLocaleDateString() + " " + ultimaFecha.toLocaleTimeString() }
              </p>
              <MapaOSM
                latitud={ultimaLatitud}
                longitud={ultimaLongitud}
              />
            </Col>
          </Col>*/}
        </Row>
      </div>

      {/* <div className="container-fluid card">
        <Row className="my-3">
          <Col xs={12} sm={6} md={4} lg={3}>
            <WidgetStat
              icon={icono1}
              number={datosAuth.simpleTxs.info.length}
              title="Documentos totales"
            />            
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <WidgetStat
              icon={icono2}
              number="68%"
              title="Tasa validaciones correctas"
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <WidgetStat
              icon={icono3}
              number="278"
              title="Documentos en espera"
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <WidgetStat icon={icono4} number="2/10" title="Equipos Online" />
          </Col>
        </Row>
      </div> */}
      {/* < WidgetStat4 />
      <div className="container-fluid card mt-3">
        <Row className="my-3">
          <Col xs={12} sm={12} md={3} lg={3} className="text-center">
            <h6>Lugar última autenticación POS</h6>
            <p>
              Latitud: {datosAuth.latitud} Longitud: {datosAuth.longitud}
            </p>

            <MapaOSM
              latitud={datosAuth.latitud}
              longitud={datosAuth.longitud}
            />
          </Col>
          <Col xs={12} sm={12} md={9} lg={9} className="">
            <Top3DocAuth datosAuth={datosAuth} />
          </Col>
        </Row>
      </div> */}
    </>
  );
};
