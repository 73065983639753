import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Button } from "@themesberg/react-bootstrap";
import "./components/widgetDash/styles/stats.css";
/*import icono1 from "./components/widgetDash/iconos/document_icon.png";
import icono2 from "./components/widgetDash/iconos/document_wait.png";
import icono3 from "./components/widgetDash/iconos/pos.png";
import icono4 from "./components/widgetDash/iconos/fingerprint-scanner.png";
import icono5 from "./components/widgetDash/iconos/auth_wait.png";
import icono6 from './components/widgetDash/iconos/validacion_exitosa.png'
import iconoFace from './components/widgetDash/iconos/faceBio.png'
import iconoOperacion from './components/widgetDash/iconos/operation.png'
import iconoGobierno from './components/widgetDash/iconos/gobierno3.png'*/
import { SalesValueWidgetLive } from "../components/Widgets";
import { TablaValidaciones } from "../components/Tables";
//import { datosGraf } from "../../RobleDashboard.js";

export const WidgetStatsAll = (props) => {
  const datos = props.datosDash;

  const [ejesAuths, setAuths] = useState({
    ejeX: [],
    ejeY: [],
  });

  const [ejesDocs, setDocs] = useState({
    ejeX: [],
    ejeY: [],
  });

  const [ejesFace, setFace] = useState({
    ejeX: [],
    ejeY: [],
  });

  const [tablaAuths, setTablaAuths] = useState({
    ejeX: [],
    ejeY: [],
  });

  const [tablaDocs, setTablaDocs] = useState({
    ejeX: [],
    ejeY: [],
  });

  const [tablaFace, setTablaFace] = useState({
    ejeX: [],
    ejeY: [],
  });


  const resetClick = () => {

    setAuths({
      ejeX: [],
      ejeY: [],
    });

    setDocs({
      ejeX: [],
      ejeY: [],
    });

    setFace({
      ejeX: [],
      ejeY: [],
    });

    cleanTablas();

  };

  function cleanTablas(){
    setTablaAuths({
      ejeX: [],
      ejeY: [],
    });

    setTablaDocs({
      ejeX: [],
      ejeY: [],
    });

    setTablaFace({
      ejeX: [],
      ejeY: [],
    });
  }

  const primeraVez = useRef(true);
  const agregarDatos = useRef(true);
  const [botonStop, setStop] = useState('Start');

  const stopClick = () => {
    if(primeraVez.current){
      primeraVez.current = false;
      setStop('Pause');
    }
    else if(agregarDatos.current){
      agregarDatos.current = false;
      setStop('Continuar');
    }
    else{
      agregarDatos.current = true;
      setStop('Pause');
    }
  };

  const [time, setTime] = useState(new Date());

  const [timeAuths, setTimeAuths] = useState(5);
  const [timeDocs, setTimeDocs] = useState(5);
  const [timeFace, setTimeFace] = useState(5);

  function moverTablasAuths(){
    setTablaAuths(prevState => ({
      ejeX: prevState.ejeX.slice(1),
      ejeY: prevState.ejeY.slice(1)
    }));
  }

  function moverTablasDocs(){
    setTablaDocs(prevState => ({
      ejeX: prevState.ejeX.slice(1),
      ejeY: prevState.ejeY.slice(1)
    }));
  }

  function moverTablasFace(){
    setTablaFace(prevState => ({
      ejeX: prevState.ejeX.slice(1),
      ejeY: prevState.ejeY.slice(1)
    }));
  }

  useEffect(() => {
    
    const interval = setInterval(() => {
      let tiempo = new Date();
      setTime(tiempo);
      let hora = tiempo.toLocaleTimeString();
      let segundos = hora.substring(6,8);
      if(agregarDatos.current && primeraVez.current === false){
        if(parseInt(segundos)%timeAuths === 0){
          setearDatosGrafAuths.current = true;
          obtenerDatosGrafAuths();
        }
        if(parseInt(segundos)%timeDocs === 0){
          setearDatosGrafDocs.current = true;
          obtenerDatosGrafDocs();
        }
        if(parseInt(segundos)%timeFace === 0){
          setearDatosGrafFace.current = true;
          obtenerDatosGrafFace();
        }
      }
    }, 1000);

    setTimeout(() => {
      stopClick();
    }, 5000);

    //if(flag){console.log("ojo");}

    return () => {
      clearInterval(interval);
    }
  }, [timeAuths, timeDocs, timeFace]);

  if(tablaAuths.ejeX.length > 150){moverTablasAuths();}
  if(tablaDocs.ejeX.length > 150){moverTablasDocs();}
  if(tablaFace.ejeX.length > 150){moverTablasFace();}

  let token = localStorage.getItem("token");

  function removeFirstAuths(){
    setAuths(prevState => ({
      ejeX: prevState.ejeX.slice(1),
      ejeY: prevState.ejeY.slice(1)
    }));
  }

  function removeFirstDocs(){
    setDocs(prevState => ({
      ejeX: prevState.ejeX.slice(1),
      ejeY: prevState.ejeY.slice(1)
    }));
  }

  function removeFirstFace(){
    setFace(prevState => ({
      ejeX: prevState.ejeX.slice(1),
      ejeY: prevState.ejeY.slice(1)
    }));
  }

  if(ejesAuths.ejeY.length>10){removeFirstAuths();}
  if(ejesDocs.ejeY.length>10){removeFirstDocs();}
  if(ejesFace.ejeY.length>10){removeFirstFace();}

  const [datosGrafAuths, setDatosGrafAuths] = useState({});
  const [datosGrafDocs, setDatosGrafDocs] = useState({});
  const [datosGrafFace, setDatosGrafFace] = useState({});
  
  const setearDatosGrafAuths = useRef();
  const setearDatosGrafDocs = useRef();
  const setearDatosGrafFace = useRef();

  if(Object.keys(datosGrafAuths).length!=0 && setearDatosGrafAuths.current){
    let hora = time.toLocaleTimeString();
    agregarDatosGrafAuths(hora);
    setearDatosGrafAuths.current = false;
  }

  if(Object.keys(datosGrafDocs).length!=0 && setearDatosGrafDocs.current){
    let hora = time.toLocaleTimeString();
    agregarDatosGrafDocs(hora);
    setearDatosGrafDocs.current = false;
  }

  if(Object.keys(datosGrafFace).length!=0 && setearDatosGrafFace.current){
    let hora = time.toLocaleTimeString();
    agregarDatosGrafFace(hora);
    setearDatosGrafFace.current = false;
  }

  function agregarDatosGrafAuths(hora){
    setAuths(prevState => ({
      ...prevState,
      ejeY: [...prevState.ejeY, datosGrafAuths.AuthsValidadas.valor],
      ejeX: [...prevState.ejeX, hora]
    }));
    setTablaAuths(prevState => ({
      ...prevState,
      ejeY: [...prevState.ejeY, datosGrafAuths.AuthsValidadas.valor],
      ejeX: [...prevState.ejeX, hora]
    }));
  }

  function agregarDatosGrafDocs(hora){
    setDocs(prevState => ({
      ...prevState,
      ejeY: [...prevState.ejeY, datosGrafDocs.DocsFirmados.valor],
      ejeX: [...prevState.ejeX, hora]
    }));
    setTablaDocs(prevState => ({
      ...prevState,
      ejeY: [...prevState.ejeY, datosGrafDocs.DocsFirmados.valor],
      ejeX: [...prevState.ejeX, hora]
    }));
  }

  function agregarDatosGrafFace(hora){
    setFace(prevState => ({
      ...prevState,
      ejeY: [...prevState.ejeY, datosGrafFace.FaceValidadas.valor],
      ejeX: [...prevState.ejeX, hora]
    }));
    setTablaFace(prevState => ({
      ...prevState,
      ejeY: [...prevState.ejeY, datosGrafFace.FaceValidadas.valor],
      ejeX: [...prevState.ejeX, hora]
    }));
  }

  async function obtenerDatosGrafAuths(pagina, query) {
    let serviceUrl = process.env.REACT_APP_HOST_URL + "/lastAuthsValidadas";
    //let serviceUrl = process.env.REACT_APP_HOST_URL + "/lastFaceValidadas";
    //let serviceUrl = process.env.REACT_APP_HOST_URL + "/lastDocsFirmados";
    let tempDatosDash = {}; // objeto temporal para almacenar los datos

    await fetch(serviceUrl, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        pagina: pagina,
        query: query,
        //hora: time.toLocaleTimeString(),
        intervaloAuths: timeAuths,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          // Asignar valores adicionales al objeto temporal
          tempDatosDash.AuthsValidadas = serviceResponse.data;
        }
      })
      .catch((err) => {
        // alert("Error");
      });

    setDatosGrafAuths(tempDatosDash);
    // Actualizar el estado datosDash una sola vez después de que se completen ambas llamadas fetch
    /*Promise.all([fetch(serviceUrl3), fetch(serviceUrl2), fetch(serviceUrl1)])
      .then((responses) => {
        setDatosGraf(tempDatosDash);
      })
      .catch((err) => {
         alert("Error");
      });*/

  }

  async function obtenerDatosGrafDocs(pagina, query) {
    //let serviceUrl = process.env.REACT_APP_HOST_URL + "/lastAuthsValidadas";
    //let serviceUrl = process.env.REACT_APP_HOST_URL + "/lastFaceValidadas";
    let serviceUrl = process.env.REACT_APP_HOST_URL + "/lastDocsFirmados";

    let tempDatosDash = {}; // objeto temporal para almacenar los datos

    await fetch(serviceUrl, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        pagina: pagina,
        query: query,
        //hora: time.toLocaleTimeString(),
        intervaloDocs: timeDocs,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          // Asignar valores adicionales al objeto temporal
          tempDatosDash.DocsFirmados = serviceResponse.data;
        }
      })
      .catch((err) => {
        // alert("Error");
      });

    setDatosGrafDocs(tempDatosDash);
    // Actualizar el estado datosDash una sola vez después de que se completen ambas llamadas fetch
    /*Promise.all([fetch(serviceUrl3), fetch(serviceUrl2), fetch(serviceUrl1)])
      .then((responses) => {
        setDatosGraf(tempDatosDash);
      })
      .catch((err) => {
         alert("Error");
      });*/

  }

  async function obtenerDatosGrafFace(pagina, query) {
    //let serviceUrl = process.env.REACT_APP_HOST_URL + "/lastAuthsValidadas";
    let serviceUrl = process.env.REACT_APP_HOST_URL + "/lastFaceValidadas";
    //let serviceUrl = process.env.REACT_APP_HOST_URL + "/lastDocsFirmados";

    let tempDatosDash = {}; // objeto temporal para almacenar los datos

    await fetch(serviceUrl, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        pagina: pagina,
        query: query,
        //hora: time.toLocaleTimeString(),
        intervaloFace: timeFace,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          // Asignar valores adicionales al objeto temporal
          tempDatosDash.FaceValidadas = serviceResponse.data;
        }
      })
      .catch((err) => {
        //alert("Error");
      });

    setDatosGrafFace(tempDatosDash);
    // Actualizar el estado datosDash una sola vez después de que se completen ambas llamadas fetch
    /*Promise.all([fetch(serviceUrl3), fetch(serviceUrl2), fetch(serviceUrl1)])
      .then((responses) => {
        setDatosGraf(tempDatosDash);
      })
      .catch((err) => {
         alert("Error");
      });*/

  }

  return (
    <>
      

      Seleccionar rango horario:
      <div>
      <select value={timeAuths} onChange={(e) => setTimeAuths(Number(e.target.value))}>
        <option value="5">5 segundos</option>
        <option value="10">10 segundos</option>
        <option value="15">15 segundos</option>
        <option value="30">30 segundos</option>
        <option value="60">1 minuto </option>
      </select>
    </div>
      <Row>
      {<Col xs={12} sm={4} md={4} lg={9} className="mb-4 d-none d-sm-block">
            <SalesValueWidgetLive
              title="Autenticaciones Realizadas"
              value="10,567"
              valoresY={[ ejesAuths.ejeY ]}
              valoresX={ ejesAuths.ejeX }
              percentage={10.57} 
            />
      </Col>}
        <Col xs={12} sm={4} md={4} lg={3}>
          <TablaValidaciones datos={tablaAuths.ejeX.map((ejeX, index) => ({ ejeX, ejeY: tablaAuths.ejeY[index] }))} />
        </Col>
      </Row>

      <br/>

      Seleccionar rango horario:
      <div>
      <select value={timeFace} onChange={(e) => setTimeFace(Number(e.target.value))}>
        <option value="5">5 segundos</option>
        <option value="10">10 segundos</option>
        <option value="15">15 segundos</option>
        <option value="30">30 segundos</option>
        <option value="60">1 minuto </option>
      </select>
    </div>
      <Row>
      {<Col xs={12} sm={4} md={4} lg={9} className="mb-4 d-none d-sm-block">
            <SalesValueWidgetLive
              title="Validaciones Faciales"
              value="10,567"
              valoresY={[ ejesFace.ejeY ]}
              valoresX={ ejesFace.ejeX }
              percentage={10.57} 
            />
      </Col>}
      <Col xs={12} sm={4} md={4} lg={3}>
        <TablaValidaciones datos={tablaFace.ejeX.map((ejeX, index) => ({ ejeX, ejeY: tablaFace.ejeY[index] }))} />
      </Col>
      </Row>

      <br/>
      
      Seleccionar rango horario:
      <div>
      <select value={timeDocs} onChange={(e) => setTimeDocs(Number(e.target.value))}>
        <option value="5">5 segundos</option>
        <option value="10">10 segundos</option>
        <option value="15">15 segundos</option>
        <option value="30">30 segundos</option>
        <option value="60">1 minuto </option>
      </select>
    </div>
      <Row>
      {<Col xs={12} sm={4} md={4} lg={9} className="mb-4 d-none d-sm-block">
            <SalesValueWidgetLive
              title="Documentos Firmados"
              value="10,567"
              valoresY={[ ejesDocs.ejeY ]}
              valoresX={ ejesDocs.ejeX }
              percentage={10.57} 
            />
      </Col>}
      <Col xs={12} sm={4} md={4} lg={3}>
        <TablaValidaciones datos={tablaDocs.ejeX.map((ejeX, index) => ({ ejeX, ejeY: tablaDocs.ejeY[index] }))} />
      </Col>
      </Row>

      Hora actual: {time.toLocaleTimeString()}
      <br/>
        <Button style={{ marginRight: '8px', backgroundColor: '#148aa6' }}onClick={stopClick}>
            {botonStop}
        </Button>
        <Button style={{ backgroundColor: '#18a8ca' }}onClick={resetClick}>
            Reset
        </Button>

    <br/> <br/>

    </>
  );
};

export default WidgetStatsAll;
