import React, { useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIdCard,
  faFingerprint,
  faFileContract,
  faBoxOpen,
  faChartPie,
  faCog,
  faUserCheck,
  faMoneyBill,
  faFileAlt,
  faHandHoldingUsd,
  faSignOutAlt,
  faTable,
  faFileInvoice,
  faTimes,
  faCalendarAlt,
  faMapPin,
  faInbox,
  faRocket,
  faHouseUser,
  faPersonCircleCheck,
  faUserAlt,
  faCalculator,
  faChartBar,
  faChartArea,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Accordion,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import ThemesbergLogo from "../assets/img/themesberg.svg";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import RobleLogo from "../assets/img/RobleLabs_Logo.png";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";


export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [showEPAC, setShowEPAC] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);
  let token = localStorage.getItem("token");
  
  
  

  
  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = "secondary",
      badgeColor = "primary",
    } = props;
    const classNames = badgeText
      ? "d-flex justify-content-start align-items-center justify-content-between"
      : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{" "}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  useEffect(  () =>  {
    let tieneMandatoUrl = process.env.REACT_APP_HOST_URL + "/tieneEPac"; 
    
    fetch(tieneMandatoUrl, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        let serviceResponse = response.data;
        if (serviceResponse.success && serviceResponse.data && serviceResponse.data === true) {
          setShowEPAC(true)
        }
      })
      .catch((err) => {
        alert("Error");
      });
      
  });
  
  function renderNavMandato(){
    if(showEPAC)
    return (
      <NavItem
                title="Mandatos"
                icon={faMoneyBill}
                link={Routes.RobleMandatos.path}
              />
    );
  }

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.Signin.path}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>

            <Nav className="flex-column pt-3 pt-md-0">
              <div className="text-center mt-3 mb-4">
                <img src={RobleLogo} alt="Logo RobleLabs"></img>
              </div>
              <NavItem
                title="Inicio"
                icon={faHouseUser}
                link={Routes.RobleDashboard.path}
              />
              <NavItem
                title="Tiempo Real"
                icon={faChartBar}
                link={Routes.RobleGraphs.path}
              />
              <NavItem
                title="Bio Firmas"
                icon={faFileContract}
                link={Routes.RobleTransactions.path}
              />
              <NavItem
                title="Bio Solicitudes" 
                icon={faUserCheck}
                link={Routes.RobleAuthRq.path}
              />
              {renderNavMandato()}
              <NavItem
                title="Autenticaciones"
                icon={faFingerprint}
                link={Routes.RobleAuths.path}
              />
              <NavItem
                title="Doc Scans"
                icon={faIdCard}
                link={Routes.RobleDocScan.path}
              />
              <NavItem
                title="Dispositivos"
                icon={faCalculator}
                link={Routes.RobleDevices.path}
              />
              <NavItem
                title="Agentes"
                icon={faUserAlt}
                link={Routes.RobleAgents.path}
              />

              {/* <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />
                <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faChartPie} />
                <NavItem title="Settings" icon={faCog} link={Routes.Settings.path} />   */}

              <Dropdown.Divider className="my-3 border-indigo" />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
