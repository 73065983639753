import React, { useState, useEffect, useRef } from "react";
import "./components/widgetDash/styles/stats.css";
import { useHistory } from "react-router-dom";

export const WidgetStatsAll = (props) => {
    const history = useHistory();
    const userAgent = navigator.userAgent;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        //dispositivo movil
      }
      else{
        //pc
        history.push("/rgraphsadmin");
      }

    const [time, setTime] = useState(new Date());
  
    useEffect(() => {
      const interval = setInterval(() => {
        let tiempo = new Date();
        setTime(tiempo);
      }, 1000);
      return () => {
        clearInterval(interval);
      }
    }, [time]);
  
    return (
      <>
        HOLA CELULAR
        <br/> <br/>
        Hora actual: {time.toLocaleTimeString()}
      </>
    );
  };
  
  export default WidgetStatsAll;
  