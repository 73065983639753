
import React, { useState, useEffect }  from "react";
import { Card, Table } from '@themesberg/react-bootstrap';

import transactions from "../../data/transactions";
import { getPaginasMostrables } from "../../data/utils";
import { RoblePagination } from "./RoblePagination";


export const DocScans = () => {

  const totalTransactions = transactions.length;  

  const [datos, setDatos] = useState({
    authScans: null,
    totalPaginas: null,
    paginaActual: null
  }) 
  let token = localStorage.getItem('token');
  console.log("Usare token: " + token);

  useEffect(() => {

    fecthData();
  
  }, []);

  function fetchPage(pagina){
    //alert(pagina);
    if(pagina==0)
      pagina=1;
      if(pagina>datos.totalPaginas){
        pagina = datos.totalPaginas;
      }
    fecthData(pagina);
  }

  function fecthData(page) {
    let serviceUrl = process.env.REACT_APP_HOST_URL +  "/adminRegistroScans";
    fetch( serviceUrl, {
        "method": "POST",
        "headers": {
          "x-roble-key": "apikey",
          "content-type": "application/json",
          "accept": "application/json"
        },
        "body": JSON.stringify({
          token: token,
          pagina: page
        })
    })
    .then(response => response.json())
    .then( response => {
      
      let serviceResponse = response.data;
      if(serviceResponse.success){
        setDatos({
            authScans: serviceResponse.data.info,
            totalPaginas: serviceResponse.data.total,
            paginaActual: serviceResponse.data.pagina
        });
      }
    })
    .catch(err => {
      //alert("Error");
    });
  }

  function renderTableBody(){
    if( datos.authScans ){
      console.log(JSON.stringify(datos.authScans));
      return (
        <tbody>
          {datos.authScans.map(authScan => <TableRow key={`scan-${authScan.identifier}`} {...authScan} />)}
        </tbody>
      )
    }
  };

  function renderPaginas(){
    if( datos.totalPaginas > 0 ){
      let paginasMostrables = getPaginasMostrables(datos.totalPaginas, datos.paginaActual);
      
      console.log(JSON.stringify(paginasMostrables));
      //alert(JSON.stringify(paginasMostrables));
      return(
        <RoblePagination paginaActual={datos.paginaActual} totalPaginas={datos.totalPaginas} paginasMostrables={paginasMostrables} pageFetcher={fetchPage} />
      )
    }
  };


  const TableRow = (props) => {
    const { nombre, rut, dv, apellido,  tipo_documento, fecha_nacimiento, fecha_vencimiento, sexo, numero_documento, nacionalidad, profesion, 
      latitud, longitud, tipo_scan,  ts_created } = props;
    const tipoDocumento = tipo_documento == 1 ? "Antiguo" : "Nuevo";
    let fechaScan = new Date(ts_created);
    //let fecha = dateFormat(fechaScan, "dd-mm-yyyy");
    //let hora = dateFormat(fechaScan, "h:MM:ss TT");
    let fecha = fechaScan.toLocaleDateString();
    let hora = fechaScan.toLocaleTimeString();
    let rutCompleto=rut+"";
    if(dv){
      rutCompleto += "-" + dv;
    }

    return (
      <tr>
        <td>
          <span className="fw-normal">
            {rutCompleto}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {nombre}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {apellido}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {numero_documento}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {nacionalidad}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {profesion}
          </span>
        </td>
        <td>
          <span className={`fw-normal`}>
            {tipoDocumento}
          </span>
        </td>
        <td>
          <span className={`fw-normal`}>
            {fecha}
          </span>
        </td>
        <td>
          <span className={`fw-normal`}>
            {hora}
          </span>
        </td>
        
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Rut Cliente</th>
              <th className="border-bottom">Nombre</th>
              <th className="border-bottom">Apellido</th>
              <th className="border-bottom">Num Documento</th>
              <th className="border-bottom">Nacionalidad</th>
              <th className="border-bottom">Profesion</th>
              <th className="border-bottom">Tipo Documento</th>
              <th className="border-bottom">Fecha</th>
              <th className="border-bottom">Hora</th>
            </tr>
          </thead>
          {renderTableBody()}
        </Table>
        {renderPaginas()}
      </Card.Body>
    </Card>
  );
};