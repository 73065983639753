import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Image,
} from "@themesberg/react-bootstrap";
import BgImage from "../assets/img/illustrations/signin.svg";
import BgImage2 from "../assets/img/fondo_sistema.jpg";
import LogoRoble from "../assets/img/roble/logo_roble.png";
import { useHistory } from "react-router-dom";

export default () => {
  let history = useHistory();
  const [datos, setDatos] = useState({
    username: "",
    password: "",
  });

  const handleInputChange = (event) => {
    // console.log(event.target.name);
    // console.log(event.target.value);
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
  };

  const enviarDatos = (event) => {
    event.preventDefault();
    //alert('enviando datos...' + datos.username + ' ' + datos.password);
    let serviceUrl = process.env.REACT_APP_HOST_URL + "/adminLogin";

    fetch(serviceUrl, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        username: datos.username,
        password: datos.password,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        //alert( JSON.stringify(response.data) )
        let serviceResponse = response.data;
        if (serviceResponse.success && serviceResponse.data.logged) {
          localStorage.setItem("token", serviceResponse.data.token);
          // console.log("Guardado token: " + serviceResponse.data.token);
          history.push("/robledashboard");
        }
      })
      .catch((err) => {
        //alert("Error");
      });
  };

  return (
    <main className="bg" style={{ backgroundImage: `url(${BgImage2})` }}>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Ingrese a nuestra plataforma</h3>
                </div>
                <Form className="mt-4" onSubmit={enviarDatos}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Tu usuario</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        name="username"
                        onChange={handleInputChange}
                        autoFocus
                        required
                        type="email"
                        placeholder="usuario@empresa"
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Contraseña</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          onChange={handleInputChange}
                          name="password"
                          placeholder="Password"
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label
                          htmlFor="defaultCheck5"
                          className="mb-0"
                        >
                          Recuérdame
                        </FormCheck.Label>
                      </Form.Check>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    Entrar
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
