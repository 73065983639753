import React, { useState, useEffect } from "react";
import { Col, Row } from "@themesberg/react-bootstrap";
import "./styles/stats.css";
import icono1 from "./iconos/document_icon.png";
import icono2 from "./iconos/document_wait.png";
import icono3 from "./iconos/pos.png";
import icono4 from "./iconos/fingerprint-scanner.png";
import icono5 from "./iconos/auth_wait.png";
import icono6 from './iconos/validacion_exitosa.png'
import iconoFace from './iconos/faceBio.png'
import iconoOperacion from './iconos/operation.png'
import iconoGobierno from './iconos/gobierno3.png'


export const WidgetStatsAll = (props) => {
  const datos = props.datosDash;
   console.log(datos);

  return (
    <>
      <Row className="my-3">
        {/* Widget 1  */}
        <Col xs={12} sm={4} md={4} lg={4}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              <div className="align-self-center">
                <img src={icono1} alt="Icono" />
              </div>
              <div className="media-body text-right">
                <h3>{datos.documentosMes + " / " + datos.documentosTotales}</h3>
                <span>Documentos Mes / Año</span>
                
              </div>  
            </Col>
          </Row>
        </Col>
        {/* Widget 2  */}
        <Col xs={12} sm={4} md={4} lg={4}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              <div className="align-self-center">
                <img src={icono2} alt="Icono" />
              </div>
              <div className="media-body text-right">
                <h3>{datos.documentosPendientes}</h3>
                <span>Documentos en espera</span>
              </div>
            </Col>
          </Row>
        </Col>
        {/* Widget 3  */}
        <Col xs={12} sm={4} md={4} lg={4}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              <div className="align-self-center">
                <img src={icono3} alt="Icono" />
              </div>
              <div className="media-body text-right">
                <h3>{datos.equiposOnline}</h3>
                <span>Equipos Operativos Hoy</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 mb-3">
        {/* Widget 4  */}
        <Col xs={12} sm={4} md={4} lg={4}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              <div className="align-self-center">
                <img src={icono4} alt="Icono" />
              </div>
              <div className="media-body text-right">
                <h3>{datos.autorizacionesTotalesMes}</h3>
                <span>Autorizaciones Este Mes</span>
              </div>
            </Col>
          </Row>
        </Col>
        {/* Widget 5  */}
        <Col xs={12} sm={4} md={4} lg={4}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              <div className="align-self-center">
                <img src={icono5} alt="Icono" />
              </div>
              <div className="media-body text-right">
                <h3>{datos.autorizacionesPendientes}</h3>
                <span>Autorizaciones pendientes</span>
              </div>
            </Col>
          </Row>
        </Col>
        {/* Widget 6  */}
        <Col xs={12} sm={4} md={4} lg={4}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              <div className="align-self-center">
                <img src={icono6} alt="Icono" />
              </div>
              <div className="media-body text-right">
                <h3>{datos.validacionesExitosasHoy}</h3>
                <span>Validaciones Exitosas Hoy</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 mb-3">
        {/* Widget 4  */}
        <Col xs={12} sm={4} md={4} lg={4}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              <div className="align-self-center">
                <img src={iconoOperacion} alt="Icono" />
              </div>
              <div className="media-body text-right">
                <h3>{datos.operacionesMes + " / " + datos.operacionesTotales }</h3>
                <span>Operaciones Mes / Año</span>
              </div>
            </Col>
          </Row>
        </Col>
        {/* Widget 5  */}
        <Col xs={12} sm={4} md={4} lg={4}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              <div className="align-self-center">
                <img src={iconoFace} alt="Icono" />
              </div>
              <div className="media-body text-right">
                <h3>{datos.facialesMes + " / " + datos.facialesTotales }</h3>
                <span>Validaciones Faciales Mes / Año</span>
              </div>
            </Col>
          </Row>
        </Col>
        {/* Widget 6  */}
        <Col xs={12} sm={4} md={4} lg={4}>
          <Row>
            { renderDataReniec(datos) }
            
          </Row>
        </Col>
        {/* Widget 7  */}
      </Row>
    </>
  );
};

function renderDataReniec(datos){
  if(datos.reniecTotales && datos.reniecTotales > 0)
  return (
    <Col className="d-flex justify-content-evenly">
        <div className="align-self-center">
          <img src={iconoGobierno} alt="Icono" />
        </div>
        <div className="media-body text-right">
          <h3>{datos.reniecMes + " / " + datos.reniecTotales }</h3>
          <span>Validaciones Reniec Mes / Año</span>
        </div>
      </Col>
  );
}

export default WidgetStatsAll;
