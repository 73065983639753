import React from "react";
import {
  Col,
  Row
} from "@themesberg/react-bootstrap";

import { SimpleAuths } from "./robleComponentes/SimpleAuths";
import { useLocation } from "react-router-dom";
import { SimpleAuth } from "./robleComponentes/SimpleAuth";

export default () => {
  const location = useLocation();
  console.log("location.state: " + JSON.stringify(location.state));
  var mAuth;
  if (location.state) {
    mAuth = location.state.auth;
    window.localStorage.setItem("currentAuth", mAuth);
  } else {
    mAuth = window.localStorage.getItem("currentAuth");
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0"></div>
        <div className="btn-toolbar mb-2 mb-md-0 ">
          {
            // <ButtonGroup>
            //   <Button variant="outline-primary" size="sm">Share</Button>
            //   <Button variant="outline-primary" size="sm">Export</Button>
            // </ButtonGroup>
          }
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* 
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
            */}
          </Col>
        </Row>
      </div>

      <SimpleAuth auth={mAuth} />
    </>
  );
};
