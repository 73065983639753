import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export const Devices = () => {
  // Obtener datos del servicio de dispositivos

  const [datosDevice, setDatosDevice] = useState({
    devices: null,
  });

  let token = localStorage.getItem("token");
  // console.log("Usare token: " + token);

  useEffect(() => {
    fecthData();
  }, []);

  function fecthData(pagina, query) {
    let serviceUrl = process.env.REACT_APP_HOST_URL + "/dispositivos";
    // console.log("Service URL: " + serviceUrl);
    fetch(serviceUrl, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        pagina: pagina,
        query: query,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        //alert( JSON.stringify(response.data) )
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          // alert("Llega data ");
          datosDevice.devices = serviceResponse.data;
          // { console.log (datosDevice.devices)}
          setDatosDevice({
            devices: datosDevice.devices,
          });
        }
      })
      .catch((err) => {
        //alert("Error");
      });
  }

  function renderTableBody() {
    if (datosDevice.devices) {
      console.log("All data: " + JSON.stringify(datosDevice.devices));
      return (
        <tbody>
          {datosDevice.devices.map((dispositivo, index) => (
            <TableRow
              key={dispositivo.serialNumber}
              index={index + 1}
              {...dispositivo}
            />
          ))}
        </tbody>
      );
    }
  }

  const TableRow = (props) => {
    // console.log("Props: " + JSON.stringify(datosDevice));   
    const { index, agenteActual, estado, serialNumber, ultimaVezOnline } = props;
    return (
      <tr>
        <td>
          <span className="fw-normal">{index}</span>
        </td>
        <td>
          <span className="fw-normal">{serialNumber}</span>
        </td>
        <td>
          <span className="fw-normal">{agenteActual}</span>
        </td>
        <td>
          <span className="fw-normal">{ultimaVezOnline}</span>
        </td>
        <td>
          <span className="fw-normal">{estado}</span>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div>
        <Row
          className="justify-content-between align-items-center"
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Col className="text-center mb-3">
            <form className="commentForm" onSubmit={""}>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  onChange={""}
                  name="identificadorAuth"
                  type="text"
                  placeholder="Buscar Dispositivo..."
                />
                <Button onClick="">Buscar</Button>
              </InputGroup>
            </form>
          </Col>
        </Row>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          {this ? this.props.query : ""}
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">#</th>
                <th className="border-bottom">Serial Number</th>
                <th className="border-bottom">Nombre del responsable</th>
                <th className="border-bottom">Última vez Online</th>
                <th className="border-bottom">Estado</th>
              </tr>
            </thead>
            {renderTableBody()}
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};
