import React, { useState, useEffect } from "react";
import { Card, Table } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../routes";
import { RoblePagination } from "./RoblePagination";
import { getPaginasMostrables } from "../../data/utils";
import {
  Col,
  Row,
  Form,
  Button,
  InputGroup
  
} from "@themesberg/react-bootstrap";

export const AuthTransactions = () => {
  const [datos, setDatos] = useState({
    authTxs: null,
    totalPaginas: null,
    paginaActual: null,
    queryString: null,
  });
  let token = localStorage.getItem("token");
  // console.log("Usare token: " + token);

  useEffect(() => {
    window.localStorage.setItem("trxToFind", "");
    fecthData();
  }, []);

  function fetchPage(pagina) {
    //alert(pagina);
    let query = window.localStorage.getItem("trxToFind");
    if (pagina === 0) pagina = 1;
    if (pagina > datos.totalPaginas) {
      pagina = datos.totalPaginas;
    }
    fecthData(pagina, query ? query : null);
  }

  function fecthData(pagina, query) {
    let serviceUrl = process.env.REACT_APP_HOST_URL + "/adminTxs";
    // console.log("Service URL: " + serviceUrl);
    fetch(serviceUrl, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        pagina: pagina,
        query: query,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        //alert( JSON.stringify(response.data) )
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          // alert("Llega data ");
          datos.authTxs = serviceResponse.data;
          setDatos({
            authTxs: serviceResponse.data.info,
            totalPaginas: serviceResponse.data.total,
            paginaActual: serviceResponse.data.pagina,
          });
        }
      })
      .catch((err) => {
        //alert("Error");
      });
  }

  function getDownloadLink(txId) {
    return (
      process.env.REACT_APP_HOST_URL +
      "/downloadTransaction?txId=" +
      txId +
      "&token=" +
      token +
      "&context=" +
      window.location.origin
    );
  }

  function renderTableBody() {
    if (datos.authTxs) {
      // console.log("All data: " + JSON.stringify(datos.authTxs));
      return (
        <tbody>
          {datos.authTxs.map((authTx) => (
            <TableRow key={`transaction-${authTx.id_tx}`} {...authTx} />
          ))}
        </tbody>
      );
    }
  }

  function getInformeTransactionsCSVLink(){
    console.log("Obteniedno link csv");
    return process.env.REACT_APP_HOST_URL + "/downloadTransactionsCSV?token="+token + "&context=" + window.location.origin;
  }

  function renderPaginas() {
    if (datos.totalPaginas > 0) {
      let paginasMostrables = getPaginasMostrables(
        datos.totalPaginas,
        datos.paginaActual
      );

      // console.log(JSON.stringify(paginasMostrables));
      //alert(JSON.stringify(paginasMostrables));
      return (
        <RoblePagination
          paginaActual={datos.paginaActual}
          totalPaginas={datos.totalPaginas}
          paginasMostrables={paginasMostrables}
          pageFetcher={fetchPage}
        />
      );
    }
  }

  function renderEstado(estado, identifier) {
    // console.log("---> El estado es " + estado);
    if (estado === "Success") {
      // console.log("SUCCESS!");
      return (
        <span className={`fw-normal`}>
          <a href={getDownloadLink(identifier)}>
            <Button size="sm">Success</Button>
          </a>
        </span>
      );
    } else {
      // console.log("NO SUCCESS!");
      return <span className={`fw-normal`}>{estado}</span>;
    }
  }

  const TableRow = (props) => {
    
    const {
      nombre,
      descripcion,
      rut,
      dv,
      ts_created,
      identifier,
      dueDate,
      estado,
      documentos,
      user_nombre,
      user_apellido,
    } = props;
    {
      /* console.log("Resultado es " + estado); */
    }
    console.log("Resultado es ", estado);
    const statusVariant =
      estado === 0 ? "No Completada" : estado === 1 ? "Success" : "Cancelada";
    const statusStyle = estado === 0 ? "" : estado === 1 ? "Success" : "Fail";
    const numDocumentos = documentos.length;

    let fechaScan = new Date(ts_created);
    //let fecha = dateFormat(fechaScan, "dd-mm-yyyy");
    //let hora = dateFormat(fechaScan, "h:MM:ss TT");
    let fecha = fechaScan.toLocaleDateString();
    let hora = fechaScan.toLocaleTimeString();

    let nombreCompleto = "";
    let rutCompleto=rut+"";
    if(dv){
      rutCompleto += "-" + dv;
    }
    if (user_nombre) nombreCompleto += user_nombre;
    if (user_apellido) nombreCompleto += user_apellido;

    return (
      <tr>
        <td>
          <span className="fw-normal">
            {rutCompleto}
          </span>
        </td>
        <td>
          <span className="fw-normal">{nombreCompleto}</span>
        </td>
        <td>
          <Card.Link
            as={Link}
            to={{
              pathname: Routes.RobleTransaction.path,
              state: { transaction: identifier },
            }}
            className="fw-normal"
          >
            {nombre}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{fecha}</span>
        </td>
        <td>
          <span className="fw-normal">{hora}</span>
        </td>
        <td>
          <span className="fw-normal">{numDocumentos}</span>
        </td>
        <td>{renderEstado(statusVariant, identifier)}</td>
      </tr>
    );
  };

  function settrxToFind(e) {
    let trxToFind = e.target.value;
    // console.log("trxToFind", trxToFind);
    window.localStorage.setItem("trxToFind", trxToFind);
  }

  function fetchTrx() {
    fetchPage(datos.paginaActual);
  }
  const onFormSubmit = (e) => {
    e.preventDefault();
    fetchTrx();
  };

  return (
    <div>
      <div>
        <Row
          className="justify-content-between align-items-center"
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Col className="text-center mb-3">
            <form className="commentForm" onSubmit={onFormSubmit}>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  onChange={settrxToFind.bind(this)}
                  name="identificadorAuth"
                  type="text"
                  placeholder="Buscar Transaccion"
                />
                <Button onClick={() => fetchTrx()}>Buscar</Button>
              </InputGroup>
            </form>
          </Col>
        </Row>
      </div>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          {this ? this.props.query : ""}
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Rut Cliente</th>
                <th className="border-bottom">Cliente</th>
                <th className="border-bottom">Operación</th>
                <th className="border-bottom">Fecha</th>
                <th className="border-bottom">Hora</th>
                <th className="border-bottom"># Documentos</th>
                <th className="border-bottom">Estado</th>
              </tr>
            </thead>
            {renderTableBody()}
          </Table>
          {renderPaginas()}

          <a href={getInformeTransactionsCSVLink("")}>
            <Button size="sm">Descargar Informe CSV</Button>
          </a>
        </Card.Body>
        
      </Card>
     
    </div>
  );
};
