

export function getPaginasMostrables(totalPaginas, paginaActual){
    let paginasMostrables = [];

    if(totalPaginas < 5){
        //console.log("Paginas totales menos de 5");
        for (let index = 1; index <= totalPaginas; index++) {
          paginasMostrables.push(index);
        }
      } else {  // hay mas de 5 paginas
        //console.log("Mas de 5 paginas");
        paginasMostrables.push(1); paginasMostrables.push(2);
        let paginaAnterior = paginaActual - 1;
        let paginaPosterior = paginaActual + 1;

        if(paginaActual <= 3){
          paginasMostrables.push(3);
          paginasMostrables.push(4);
        } else {
          //console.log("paginaAnterior" + paginaAnterior);
          if(paginaAnterior > 3){
            //console.log("Agregando .. previo");
            paginasMostrables.push("..");
          } else {
            //console.log("NO Agregando .. previo");
          }
          paginasMostrables.push(paginaAnterior);
          paginasMostrables.push(paginaActual);
          if( totalPaginas >= paginaPosterior){
            paginasMostrables.push(paginaPosterior);
          }
        }
        if(totalPaginas > paginaPosterior+1){
          paginasMostrables.push("..");
        }
        if( totalPaginas > paginaPosterior ){
          paginasMostrables.push(totalPaginas);
        } 
      }
      return paginasMostrables;
}