import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, ButtonGroup, Dropdown } from '@themesberg/react-bootstrap';
import { useHistory } from "react-router-dom"
import { Mandatos } from "../pages/robleComponentes/Mandatos";

var history;

export default () => {
  history = useHistory();  
  let token = localStorage.getItem("token");

  const [datosTotal, setDatosTotal] = useState({
    mandatosDia: 0,
    mandatosMes: 0,
    mandatosAgno: 0
  });

  useEffect(() => {
    fetchTotales();
  }, []);

  function fetchTotales(){

    let totalMandatosUrl = process.env.REACT_APP_HOST_URL + "/mandatosData"; 
    fetch(totalMandatosUrl, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        let serviceResponse = response.data.data;
        console.log("Response ", serviceResponse);
        setDatosTotal({
          mandatosDia: serviceResponse.dia,
          mandatosMes: serviceResponse.mes,
          mandatosAgno: serviceResponse.agno
        });
      })
      .catch((err) => {
        alert("Error");
      });

  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          {/*
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
              <Breadcrumb.Item active>Transacciones</Breadcrumb.Item>
            </Breadcrumb>
          */}
          
          <h4>Mandatos</h4>
          
        </div>

        <Col xs={12} sm={4} md={3} lg={3}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              
              <div className="media-body text-right">
                <h3>{datosTotal.mandatosDia}</h3>
                <span>Mandatos Hoy</span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              
              <div className="media-body text-right">
                <h3>{datosTotal.mandatosMes}</h3>
                <span>Mandatos este Mes</span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={12} sm={3} md={3} lg={3}>
          <Row>
            <Col className="d-flex justify-content-evenly">
              
              <div className="media-body text-right">
                <h3>{datosTotal.mandatosAgno}</h3>
                <span>Mandatos este Año</span>
              </div>
            </Col>
          </Row>
        </Col>
       
       
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* 
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
            */}
            
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <Mandatos/>
    </>
  );
};
