import React, { useState, useEffect } from "react";
import {
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Table,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Routes } from "../../routes";
import { getPaginasMostrables } from "../../data/utils";
import { RoblePagination } from "./RoblePagination";

export const SimpleAuths = () => {
  const [datos, setDatos] = useState({
    authTxs: null,
    totalPaginas: null,
    paginaActual: null,
  });
  let token = localStorage.getItem("token");

  useEffect(() => {
    window.localStorage.setItem("authToFind", "");    
    fecthData();
  }, []);

  function fetchPage(pagina) {
    //alert("Solicitando... " + pagina);
    let query = window.localStorage.getItem("authToFind");
    console.log("Buscare " + query);
    if (pagina === 0) pagina = 1;
    if (pagina > datos.totalPaginas) {
      pagina = datos.totalPaginas;
    }
    fecthData(pagina, query);
  }

  function fecthData(pagina, query) {
    let serviceUrl = process.env.REACT_APP_HOST_URL + "/adminAuths";
    fetch(serviceUrl, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        pagina: pagina,
        query: query,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        //alert( JSON.stringify(response.data) )
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          // alert("Llega data ");
          datos.authTxs = serviceResponse.data;   
          setDatos({
            authTxs: serviceResponse.data.info,
            totalPaginas: serviceResponse.data.total,
            paginaActual: serviceResponse.data.pagina,
          });
        }
      })
      .catch((err) => {
        //alert("Error");
      });
  }

  function getInformeAuthsCSVLink(){
    console.log("Obteniedno link csv");
    return process.env.REACT_APP_HOST_URL + "/downloadAuthsCSV?token="+token + "&context=" + window.location.origin;
  }

  function getDownloadLink(authId) {
    //alert("A descargar " + authId);
    return (
      process.env.REACT_APP_HOST_URL +
      "/downloadAuth?auth=" +
      authId +
      "&token=" +
      token +
      "&context=" +
      window.location.origin
    );
  }

  function getAuthLinkObj(authId) {
    //alert(authId);
    if (authId) {
      return {
        pathname: Routes.RobleAuth.path,
        state: { auth: authId },
      };
    } else {
      return {};
    }
  }

  function renderTableBody() {
    if (datos.authTxs) {
      //console.log(JSON.stringify(datos.authTxs));
      return (
        <tbody>
          {datos.authTxs.map((authTx) => (
            <TableRow key={`transaction-${authTx.identifier}`} {...authTx} />
          ))}
        </tbody>
      );
    }
  }

  const TableRow = (props) => {
    console.log("Props:", props);
    const {
      rut,
      dv,
      nombres,
      apellidos,
      ts_created,
      documento_autenticacion,
      intentosValidacion,
      identifier,
      maquina,
      application_id,
      tipo_documento,
      resultado,
      requerimiento
    } = props;
    const statusVariant =
      resultado == 0
        ? "No Completada"
        : resultado == 1
        ? "Success"
        : "Cancelada";
    const statusStyle =
      resultado == "0" ? "" : resultado == "1" ? "Success" : "Fail";
    const numDocumentos = intentosValidacion.length;

    let fechaScan = new Date(ts_created);
    //let fecha = dateFormat(fechaScan, "dd-mm-yyyy");
    //let hora = dateFormat(fechaScan, "h:MM:ss TT");
    let fecha = fechaScan.toLocaleDateString();
    let hora = fechaScan.toLocaleTimeString();

    let mNombre = "";
    let mApellido = "";
    if (nombres) mNombre = nombres;
    if (apellidos) mApellido = apellidos;

    let nombreCompleto = mNombre + " " + mApellido;
    let rutCompleto=rut+"";
    if(dv){
      rutCompleto += "-" + dv;
    }

    return (
      <tr>
        <td>
          <span className="fw-normal">
            {rutCompleto}
          </span>
        </td>
        <td>
          <span className="fw-normal">{nombreCompleto}</span>
        </td>
        <td>
          <Card.Link
            as={Link}
            to={getAuthLinkObj(identifier)}
            className="fw-normal"
          >
            {documento_autenticacion}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{fecha} {hora}</span>
        </td>
        <td>
          <span className="fw-normal">{maquina}</span>
        </td>
        <td>
          <span className="fw-normal">{numDocumentos}</span>
        </td>
        <td>{renderEstado(statusVariant, identifier)}</td>
      </tr>
    );
  };

  function renderEstado(estado, identifier) {
    //console.log("---> El estado es " + estado);
    if (estado == "Success") {
      //console.log("SUCCESS!");
      return (
        <span className={`fw-normal`}>
          <a href={getDownloadLink(identifier)}>
            <Button size="sm">Success</Button>
          </a>
        </span>
      );
    } else {
      //console.log("NO SUCCESS!");
      return <span className={`fw-normal`}>{estado}</span>;
    }
  }

  function renderPaginas() {
    if (datos.totalPaginas > 0) {
      let paginasMostrables = getPaginasMostrables(
        datos.totalPaginas,
        datos.paginaActual
      );

      //console.log(JSON.stringify(paginasMostrables));
      //alert(JSON.stringify(paginasMostrables));
      return (
        <RoblePagination
          paginaActual={datos.paginaActual}
          totalPaginas={datos.totalPaginas}
          paginasMostrables={paginasMostrables}
          pageFetcher={fetchPage}
        />
      );
    }
  }

  function setAuthToFind(e) {
    let authToFind = e.target.value;
    console.log("authToFind", authToFind);
    window.localStorage.setItem("authToFind", authToFind);
  }

  function fetchAuths() {
    fetchPage(datos.paginaActual);
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    fetchAuths();
  };

  return (
    <>
      <Row
        className="justify-content-between align-items-center"
        xs={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Col className="text-end mb-3">
          <form className="commentForm" onSubmit={onFormSubmit}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                onChange={setAuthToFind.bind(this)}
                name="identificadorAuth"
                type="text"
                placeholder="Buscar Autorización"
              />
              <Button type="submit" onClick={() => fetchAuths()}>
                Buscar
              </Button>
            </InputGroup>
          </form>
        </Col>
      </Row>

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Rut Cliente</th>
                <th className="border-bottom">Cliente</th>
                <th className="border-bottom">Documento</th>
                <th className="border-bottom">Fecha Solicitud</th>
                <th className="border-bottom">Dispositivo</th>
                {/* <th className="border-bottom">Requerimiento</th> */}
                <th className="border-bottom"># Intentos</th>
                <th className="border-bottom">Estado</th>
              </tr>
            </thead>
            {renderTableBody()}
          </Table>
          {renderPaginas()}

          <a href={getInformeAuthsCSVLink("")}>
            <Button size="sm">Descargar Informe CSV</Button>
          </a>
        </Card.Body>
      </Card>
    </>
  );
};
