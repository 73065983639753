import { Col, Row } from "@themesberg/react-bootstrap";
import React from "react";
import "./styles/mapa.css";
import "leaflet/dist/leaflet.css";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

export const mapaOSM = (props) => {
  const position = [props.latitud, props.longitud];

  const customIcon = L.icon({
    iconUrl: require("../../../assets/img/pin.png"),
    iconSize: [30, 30],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
  });

  return (
    <>
      <Row className="text-center">
        <Map center={position} zoom={15}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position} icon={customIcon}>
            <Popup>Última autorización de POS</Popup>
          </Marker>
        </Map>
      </Row>
    </>
  );
};

export default mapaOSM;
