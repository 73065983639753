import React from "react";
import { Col, Row } from '@themesberg/react-bootstrap';
import { useLocation } from "react-router-dom";
import { PublicAuth } from "./robleComponentes/PublicAuth";

export default (props) => {

  //      http://localhost:3000/#/pubauth?auth=aaaa-bbbb
  console.log("PubAuth...", props);
  const location = useLocation();
  console.log("Location", location);
  let mAuth = location.search.substring(1);
  if(!mAuth)
    mAuth=location.query;

  console.log("Buscando " + mAuth);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/*
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
  */}
            
          </Col>
          
        </Row>
      </div>

      <PublicAuth auth={mAuth}/>
    </>
  );
};
