import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Table,
  Form
} from "@themesberg/react-bootstrap";
import MapaOSM from "../components/widgetDash/widgetMap";
import "./styles/mapa.css";


export const SimpleAuth = (props) => {
  const [datos, setDatos] = useState({
    autenticacion: null,
  });

  let token = localStorage.getItem("token");
  //console.log("Usare token: " + token);
  //console.log("Props: " + JSON.stringify(props));

  useEffect(() => {
    fecthData();
  }, []);

  function fecthData() {
    let serviceUrl = process.env.REACT_APP_HOST_URL + "/adminAuth";
    console.log("Service URL: " + serviceUrl);
    fetch(serviceUrl, {
      method: "POST",
      headers: {
        "x-roble-key": "apikey",
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        token: token,
        autenticacion: props.auth,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        //alert( JSON.stringify(response.data) )
        let serviceResponse = response.data;
        if (serviceResponse.success) {
          //alert("Llega data ");
          //datos.authTxs = serviceResponse.data
          setDatos({
            autenticacion: serviceResponse.data,
          });
        }
      })
      .catch((err) => {
        alert("Error");
      });
  }

  function getDownloadLink(authId) {
    return (
      process.env.REACT_APP_HOST_URL +
      "/downloadAuth?auth=" +
      authId +
      "&token=" +
      token +
      "&context=" +
      window.location.origin
    );
  }

  function renderTableBody() {
    if (datos.autenticacion) {
      //console.log("All data: " + JSON.stringify(datos.autenticacion));
      return (
        <tbody>
          {renderIntentosTable(datos.autenticacion.intentosValidacion)}
        </tbody>
      );
    }
  }

  function renderIntentosTable(intentos) {
    if (intentos) {
      return intentos.map((intento) => (
        <IntentoRow key={`transaction-${intento.identifier}`} {...intento} />
      ));
    }
  }

  const IntentoRow = (props) => {
    // console.log("Props: " + JSON.stringify(props));
    const { identifier, ts_updated, estado } = props;
    // console.log("Resultado es " + estado);

    const statusVariant =
      estado == "0" ? "Fail" : estado == "1" ? "Success" : "Cancelada";

    let fechaScan = new Date(ts_updated);
    //let fecha = dateFormat(fechaScan, "dd-mm-yyyy");
    //let hora = dateFormat(fechaScan, "h:MM:ss TT");
    let fecha = fechaScan.toLocaleDateString();
    let hora = fechaScan.toLocaleTimeString();
    let tipo = props.tipo;
    let tipoStr = "";
    switch (tipo) {
      case 1:
        tipoStr = "Dactilar Gobierno";
        break;
      case 2:
        tipoStr = "Dactilar MatchOnCard";
        break;
      case 3:
        tipoStr = "Facial vs CI";
        break;
      case 4:
        tipoStr = "Facial vs Scan";
        break;
      case 5:
        tipoStr = "Dactilar Enrolado";
        break;
    }

    return (
      <tr>
        <td>
          <span className="fw-normal">{identifier}</span>
        </td>
        <td>
          <span>{statusVariant}</span>
        </td>
        <td>
          <span>{tipoStr}</span>
        </td>
        <td>
          <span className="fw-normal">{fecha}</span>
        </td>
        <td>
          <span className="fw-normal">{hora}</span>
        </td>
      </tr>
    );
  };

  function renderTxInfo() {
    if (datos.autenticacion) {
      const autenticacion = datos.autenticacion;
      //console.log("Autenticacion: ", autenticacion);
      const estadoAuth =
        autenticacion.resultado == "0"
          ? "No Completada"
          : autenticacion.resultado == "1"
          ? "Success"
          : "Cancelada";

      let fechaScan = new Date(autenticacion.ts_updated);
      //let fechaHora = dateFormat(fechaScan, "dd-mm-yyyy h:MM:ss TT");
      let fechaHora =
        fechaScan.toLocaleDateString() + " " + fechaScan.toLocaleTimeString();

      if (!autenticacion.latitud) {
        autenticacion.latitud = "";
      }
      if (!autenticacion.longitud) autenticacion.longitud = "";

      let visadores = "";
      if (autenticacion.visadores) {
        visadores = autenticacion.visadores.join(",");
      }

      function renderUserFaceImage() {
        if (autenticacion.userFace) {
          return (
            <img src={`data:image/png;base64,${autenticacion.userFace}`} />
          );
        }
      }
      function renderUserSignImage() {
        if (autenticacion.userSign) {
          return (
            <img
              className="top-border-sm"
              src={`data:image/png;base64,${autenticacion.userSign}`}
            />
          );
        }
      }

      let rutCompleto=autenticacion.rut+"";
      if(autenticacion.dv){
        rutCompleto += "-" + autenticacion.dv;
      }
      return (
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h4>
              Autorización<h6>{autenticacion.identifier}</h6>
            </h4>
            <Form>
              <Row>
                <Col md={2} className="mb-2">
                  <Row>{renderUserFaceImage()}</Row>
                  <Row>{renderUserSignImage()}</Row>
                </Col>
                <Col className="">
                  <Row>
                    <Form.Group id="firstName">
                      <Form.Label>Rut</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={rutCompleto}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group id="lastName">
                      <Form.Label>Nombres</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={autenticacion.nombres}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group id="birthday">
                      <Form.Label>Apellidos</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={autenticacion.apellidos}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group id="phone">
                      <Form.Label>Documento</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        value={autenticacion.documento_autenticacion}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group id="xxx" className="top-border-sm">
                      <a href={getDownloadLink(autenticacion.identifier)}>
                        <Button size="sm">Descargar Comprobante</Button>
                      </a>
                    </Form.Group>
                  </Row>
                </Col>
              </Row>

              <Row className="align-items-center">
                <Col md={6} className="mb-3">
                  {renderEstado(estadoAuth)}
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="agente">
                    <Form.Label>Agente</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={autenticacion.agente}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="emal">
                    <Form.Label>Fecha Creación</Form.Label>
                    <Form.Control required type="text" value={fechaHora} />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="emal3">
                    <Form.Label>Ubicacion</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={
                        autenticacion.latitud + ", " + autenticacion.longitud
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="emal">
                    <Form.Label>Revisado Por</Form.Label>
                    <Form.Control required type="text" value={visadores} />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <div className="divMapa">
                    <MapaOSM
                      latitud={autenticacion.latitud}
                      longitud={autenticacion.longitud}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      );
    }
  }

  function renderEstado(estado) {
    // console.log("---> El estado es " + estado);
    if (estado == "success") {
      // console.log("SUCCESS!");
      return (
        <Button size="sm" variant="primary" className="copy-code-button">
          Success
        </Button>
      );
    } else {
      // console.log("NO SUCCESS!");
      return (
        <Form.Group id="gender">
          <Form.Label>Estado</Form.Label>
          <Form.Control required type="text" value={estado} />
        </Form.Group>
      );
    }
  }

  function renderIntentos() {
    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body>
          <h5 className="mb-4">Intentos de Validación</h5>
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Identificador</th>
                <th className="border-bottom">Estado</th>
                <th className="border-bottom">Tipo</th>
                <th className="border-bottom">Fecha</th>
                <th className="border-bottom">Hora</th>
              </tr>
            </thead>
            {renderTableBody()}
          </Table>
        </Card.Body>
      </Card>
    );
  }

  return (
    <div>
      {renderTxInfo()}
      {renderIntentos()}
    </div>
  );
};
