
import React, { useState, useEffect }  from "react";
import { Col, Row, Card, Button, Table, Form } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";

export const AuthTransaction = (props) => {

  const [datos, setDatos] = useState({
    authTx: null
  });
  
  
  let token = localStorage.getItem('token');
  //console.log("Usare token: " + token);
  //console.log("Props: " + JSON.stringify(props));

  useEffect(() => {
    fecthData();  
  }, []);

  function fecthData() {
      let serviceUrl = process.env.REACT_APP_HOST_URL +  "/adminTx";
      fetch( serviceUrl, {
        "method": "POST",
        "headers": {
          "x-roble-key": "apikey",
          "content-type": "application/json",
          "accept": "application/json"
        },
        "body": JSON.stringify({
          token: token,
          transaction: props.tx
        })
    })
    .then(response => response.json())
    .then(response => {
      //alert( JSON.stringify(response.data) )
      let serviceResponse = response.data;
      if(serviceResponse.success){
        //alert("Llega data ");
        //datos.authTxs = serviceResponse.data
        setDatos({
          authTx: serviceResponse.data
        });
      }
    })
    .catch(err => {
      //alert("Error");
    });
  }

  function getDownloadLink(txId){
    return process.env.REACT_APP_HOST_URL + "/downloadTransaction?txId=" + txId + "&token="+token + "&context=" + window.location.origin;
  }

  function renderTableBody(){
    if( datos.authTx ){
      //console.log("All data: " + JSON.stringify(datos.authTxs));
      return (
        <tbody>
          {renderDocsTable(datos.authTx.documentos)}
        </tbody>
      )
    }
    
  };

  function renderDocsTable(documents){
    if(documents){
      return documents.map(documento => <DocumentRow key={`transaction-${documento.identifier}`} {...documento} />);
    }
  }


  const DocumentRow = (props) => {
    //console.log("Props: " + JSON.stringify(props));
    const { nombre, descripcion, ts_updated, id_documento, estado, numIntentos, autenticacion, reference_url, agente, documentos } = props;
    // console.log("Resultado authTx es " + estado);
    
    const statusVariant = estado == "0" ? "No Completada" 
        : estado == "1" ? "Success" : "Cancelada";
    const statusStyle = estado == "0" ? "" 
        : estado == "1" ? "Success" : "Fail";
    
    let fechaScan = new Date(ts_updated);
    //let fecha = dateFormat(fechaScan, "dd-mm-yyyy");
    //let hora = dateFormat(fechaScan, "h:MM:ss TT");
    let fecha = fechaScan.toLocaleDateString();
    let hora = fechaScan.toLocaleTimeString();

    let myReferenceUrl = window.location.href;
    if(reference_url){
      console.log("No hay reference");
      myReferenceUrl = reference_url;
    }

    let fechaHora = fecha + " " + hora;

    return (
      <tr>
        
        <td>
          <span className="fw-normal">
            {id_documento}
          </span>
        </td>
        <td>
          <span>
              {nombre}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {descripcion}
          </span>
        </td>
        <td>
          <Card.Link className="fw-normal" as={Link} to={getAuthLinkObj(autenticacion)}>
            {statusVariant}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">
            {agente}
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {fechaHora}
          </span>
        </td>
        
        <td>
          <span className={`fw-normal`}>
            {numIntentos}
          </span>
        </td>
        <td>
          <span className={`fw-normal`}>
            <a href={myReferenceUrl} target="_blank" rel="noopener noreferrer">Link</a>
          </span>
        </td>
      </tr>
    );
  };

  function getAuthLinkObj(authId){
    if(authId){
      return {
        pathname: Routes.RobleAuth.path,
        state: {auth: authId}
      }
    } else {
      return {}
    }
  }

  function renderUserFaceImage(){
    if(datos.authTx && datos.authTx.userFace){
      return(
        <img src={`data:image/png;base64,${datos.authTx.userFace}`}/>
      )
    }
  }
  function renderUserSignImage(){
    if(datos.authTx && datos.authTx.userSign){
      return(
        <img className="top-border-sm" src={`data:image/png;base64,${datos.authTx.userSign}`}/>
      )
    }
  }

  function renderUserInfo(){

    if(datos.authTx){
      const usuario = datos.authTx.usuario;
      let fechaNacimiento=usuario.fecha_nacimiento;
      /*
      if(usuario.fecha_nacimiento){
        let fechaNacimientoDate = new Date(usuario.fecha_nacimiento);
        fechaNacimiento = dateFormat(fechaNacimientoDate, "dd-mm-yyyy");
      }*/
      let rutCompleto=usuario.rut+"";
      if(usuario.dv){
        rutCompleto += "-" + usuario.dv;
      }

      return(
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h4>Detalle de Cliente</h4>
          <Form>
            <Row>
                <Col md={2} className="mb-2">
                  <Row>
                    <Form.Group id="birthday">
                      <Form.Label className="top-border-sm">Rut: <b>{rutCompleto}</b></Form.Label>
                    </Form.Group>
                  </Row>
                  {renderUserFaceImage()}
                  {renderUserSignImage()}
                </Col>
                <Col md={6} className="mb-3">
                  <Row>
                    <Form.Group id="firstName">
                      <Form.Label>Nombre</Form.Label>
                      <Form.Control required type="text" value={usuario.nombre} />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group id="lastName">
                      <Form.Label>Apellido</Form.Label>
                      <Form.Control required type="text" value={usuario.apellido} />
                    </Form.Group>
                  </Row>                  
                  <Form.Group id="gender">
                    <Form.Label>Fecha Nacimiento</Form.Label>
                    <Form.Control required type="text" value={fechaNacimiento} />
                  </Form.Group>
                  <Form.Group id="emal">
                    <Form.Label>Nacionalidad</Form.Label>
                    <Form.Control required type="text" value={usuario.nacionalidad} />
                  </Form.Group>
                  <Form.Group id="phone">
                    <Form.Label>Profesion</Form.Label>
                    <Form.Control required type="number" value={usuario.profesion} />
                  </Form.Group>
                </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                
              </Col>
              <Col md={6} className="mb-3">
                
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={6} className="mb-3">
                
              </Col>
              <Col md={6} className="mb-3">
                
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                
              </Col>
              <Col md={6} className="mb-3">
                
              </Col>
            </Row>
            
            </Form>
          </Card.Body>
        </Card>
      )
    }
    
  }


  function renderTxInfo(){

    if(datos.authTx){

      const authTx = datos.authTx;
      const estadoTx = authTx.estado == "0" ? "No Completada" 
        : authTx.estado == "1" ? "Success" : "Cancelada";

      let fechaScan = new Date(authTx.ts_updated);
      let fechaHora = fechaScan.toLocaleDateString() + " " + fechaScan.toLocaleTimeString();
      //let fechaHora = dateFormat(fechaScan, "dd-mm-yyyy h:MM:ss TT");
      // console.log("Tengo auth:" , authTx);
      let agentes = "";
      if(authTx.agentes){
        agentes = authTx.agentes.join(',');
      }
      let visadores = "";
      if(authTx.visadores){
        visadores = authTx.visadores.join(',');
      }

      return(
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h4>Detalle de Transacción<h6>{authTx.identifier}</h6></h4> 
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control required type="text" value={authTx.nombre} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control required type="text" value={authTx.descripcion} />
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={6} className="mb-3">
                <Form.Group id="birthday">
                  <Form.Label>Identificador</Form.Label>
                  <Form.Control required type="text" value={authTx.id_tx} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control required type="text" value={estadoTx} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="email">
                  <Form.Label>Fecha Creación</Form.Label>
                  <Form.Control required type="text" value={fechaHora} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="phone">
                  <Form.Label>Mensaje</Form.Label>
                  <Form.Control required type="number" value={authTx.tx_message} />
                </Form.Group>
              </Col>
             
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="agentes">
                  <Form.Label>Agentes</Form.Label>
                  <Form.Control required type="text" value={agentes} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="referenceUrl">
                  <Form.Label>URL Referencia</Form.Label>
                  <Form.Control required type="text" value={authTx.reference_url} />
                </Form.Group>
              </Col>
              
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="agentes">
                  <Form.Label>Revisado Por</Form.Label>
                  <Form.Control required type="text" value={visadores} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <a href={getDownloadLink(datos.authTx.identifier)}>
                    <Button size="sm">Descargar Comprobante</Button>
                </a>
              </Col>
            </Row>
            </Form>
          </Card.Body>
        </Card>
      )
    }
    
  }

  function renderTxDocs(){
    return (

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
          <Card.Body>
            <h5 className="mb-4">Documentos</h5>
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                <th className="border-bottom">Identificador</th>
                  <th className="border-bottom">Nombre</th>
                  <th className="border-bottom">Descripción</th>
                  <th className="border-bottom">Estado</th>
                  <th className="border-bottom">Agente</th>
                  <th className="border-bottom">Actualización</th>
                  <th className="border-bottom"># Intentos</th>
                  <th className="border-bottom">Url</th>
                </tr>
              </thead>
              {renderTableBody()}
            </Table>
          </Card.Body>
        </Card>
    )
    
  }

  return (
    <div>
      {renderTxInfo()}
      {renderUserInfo()}
      {renderTxDocs()}
    </div>
    
  );
};
